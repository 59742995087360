import React from 'react'
import "../Assect/css/Footer.css"
import whatsapp from "../Assect/image/whatsapp-1.png"
import phone from "../Assect/image/pngwing-2.png"
import mail from "../Assect/image/pngwing-1.png"
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";

function Footer() {
    return (
        <div className='App'>
            <div className='custom-footer'>
                <div className='container'>
                    <div className='custom-inside-footer'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='row'>
                                    <div className='col-lg-3'>
                                        <div className='custom-left-side-fotter'>
                                            <h2 className='mb-3'>QUICK LINKS</h2>
                                            
                                            <p className='mt-3'>Indiamoney does not buy stolen or
                                                broken gold. Selling stolen gold or
                                                fake gold is a criminal offense and
                                                if identified, the person and
                                                ornaments will be handed over to
                                                the POLICE. Anyone age of 21
                                                or less can sell gold only with
                                                Parent/guardian's written letter</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className='custom-left-one-side-footer'>
                                            <h2>REACH US</h2>
                                            <p style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                <img src={whatsapp} alt='' style={{ marginRight: '8px', width: '20px', height: '20px' , fontWeight: "bold"}} /> 6379251627
                                            </p>
                                            <p style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                <img src={phone} alt='' style={{ marginRight: '8px', width: '20px', height: '20px' , fontWeight: "bold"}} /> 6379251627
                                            </p>
                                            <p style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                <img src={mail} alt='' style={{ marginRight: '8px', width: '20px', height: '20px' }} />
                                                <h6 className='fw-bold' style={{ margin: 0 }}>business@indiamoney.co.in</h6>
                                            </p>
                                            <h6 className='fw-bold'>State Presence</h6>
                                            <p>Tamil Nadu, Pondicherry, Karnataka, Kerala</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className='custom-left-one-side-fotter'>
                                            <h2>OUR SERVICES</h2>
                                            <ul class="custom-list">
                                                <li class="">Cash For Gold</li>
                                                <li class="">Financial</li>
                                                <li class="">Home Loan</li>
                                                <li class="">Personal Loan</li>
                                                <li class="">Registration</li>
                                                <li class="">GST & Tax</li>
                                                <li class="">Instant Bank Transfer</li>
                                            </ul>
                                            <h6 className='fw-bold'>Follow us on social platforms</h6>
                                            <div className='d-flex custom-icons mt-3 mb-3'>
                                                <p><FaYoutube /></p>
                                                <p><FaFacebook /></p>
                                                <p><FaInstagram /></p>
                                                <p><FaTwitter /></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className='custom-left-one-side-fotter'>
                                            <h2>QUICK LINKS</h2>
                                            <ul class="custom-list">
                                                <li class="">Discover Indiamoney Finserv</li>
                                                <li class="">Release Pledged Gold</li>
                                                <li class="">Vision and Mission</li>
                                                <li class="">Founder's Message</li>
                                                <li class="">History and Milestones</li>
                                                <li class="">License and Certificates</li>
                                                <li class="">Gold Price Escalation</li>
                                                <li class="">Flickr Gallery</li>
                                                <li class="">FAQS</li>
                                            </ul>
                                          
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer